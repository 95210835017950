import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Modal from '../Modal';
import WithLocation from '../WithLocation';
import Cookies from 'js-cookie'
const PropertyDetailsList = ({ property, wrap = false, location }) => {
  const [showLegalTextModalOpen, setShowLegalTextModalOpen] = useState(false);
  // useEffect(() => {
  //   var showPopover = function() {
  //       $(this).popover('toggle');
  //     },
  //     hidePopover = function() {
  //       $(this).popover('hide');
  //     };
  //   $('[data-legal-text=true]')
  //     .popover({
  //       trigger: 'manual',
  //     })
  //     .click(showPopover)
  //     .blur(hidePopover);

  //   return () => {
  //     $('[data-legal-text=true]').popover('dispose');
  //   };
  // }, []);
  const params = new URLSearchParams(location.search);
  const nodisc = params.get('nodisc') == '1' ? 1 : Cookies.get('nodisc');
  console.log('nodisc3cookie', Cookies.get('nodisc'))
  console.log('nodisc3', nodisc)

  const legalText = `The number of bedrooms for our home listings may vary from data presented in conventional real-estate listings, and data on file with respect to each home's original construction. Extra sleep rooms may be based upon bonus rooms, lofts, wallbeds, sofabeds and/or other open or non-permanent fixtures. The number of bathrooms may also differ, based on the installation of outdoor showers, pet-washing facilities & similar amenities.`;
  const [showLegalText, setShowLegalText] = useState(nodisc != 1);
  console.log('showLegalText4', showLegalText)
  useEffect(() => {
    setShowLegalText(params.get('nodisc') == '1' ? 1 : Cookies.get('nodisc'))
  }, [params.get('nodisc'), Cookies.get('nodisc')])
  return (
    <div
      className={`d-flex justify-content-start p-0 ${wrap ? 'flex-wrap' : ''}`}
    >
      {showLegalText && (
        <Modal
          isOpen={showLegalTextModalOpen}
          title={'Legal notice'}
          onClose={() => setShowLegalTextModalOpen(false)}
          disableFocusTrap={true}
        >
          {legalText}
        </Modal>
      )}
      {showLegalText ? <div
        className={`${styles.amenityText} text-nowrap mr-2`}
      // onClick={() => {
      //   if (showLegalText) {
      //     setShowLegalTextModalOpen(true);
      //   }
      // }}
      // className="ml-1plus popover-dismiss"
      // data-legal-text={false}
      // data-trigger={showLegalText ? 'hover' : ''}
      // data-container="body"
      // data-toggle={showLegalText ? 'popover' : ''}
      // data-placement="top"
      // data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
      // data-content={`Legal Notice: ${legalText}`}
      >
        <>
          <span className="mr-1plus align-text-bottom">
            <img width="26" height="20" src="/bed.svg" alt="Bedrooms" />
          </span>
          Bedrooms: {property.bedrooms}
        </>
      </div> :
        <div className={`${styles.amenityText} text-nowrap mr-2`}>
          <span className="mr-1plus align-text-bottom">
            <img width="26" height="20" src="/bed.svg" alt="Bedrooms" />
          </span>
          Bedrooms: {property.bedrooms}
        </div>}
      {showLegalText ? <div
        className={`${styles.amenityText} text-nowrap mr-2`}
      // onClick={() => {
      //   if (showLegalText) {
      //     setShowLegalTextModalOpen(true);
      //   }
      // }}
      // data-trigger={showLegalText ? 'hover' : ''}
      // data-container="body"
      // data-toggle={showLegalText ? 'popover' : ''}
      // data-placement="top"
      // data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
      // data-content={`Legal Notice: ${legalText}`}
      >
        <>
          <span className="mr-1plus align-text-bottom">
            <img width="20" height="20" src="/bath.svg" alt="Bathrooms" />
          </span>
          Baths: {property.bathrooms}
        </>
      </div> : <div className={`${styles.amenityText} text-nowrap mr-2`}>
        <span className="mr-1plus align-text-bottom">
          <img width="20" height="20" src="/bath.svg" alt="Bathrooms" />
        </span>
        Baths: {property.bathrooms}
      </div>}
      <div className={`${styles.amenityText} text-nowrap mr-2`}>
        <span className="mr-1plus align-text-bottom">
          <img width="16" height="20" src="/sleeps.svg" alt="Sleeps" />
        </span>
        Sleeps: {property.sleeps}
      </div>
    </div>
  );
};

export default WithLocation(PropertyDetailsList);
