import React, { useState, useEffect } from 'react';
import SEO from '../../../components/seo';
// import Layout from '../../../layouts/DefaultLayout';
import { Formik, Field } from 'formik';
import Loading from '../../../components/Loading';
import { Query, Mutation, useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Info, Package } from 'react-feather';
import FormFeedback from '../../../components/Forms/FormFeedback';
import { objectIsEmpty } from '../../../util/helpers';
import Modal from '../../../components/Modal';
import * as Yup from 'yup';
// import './styles.scss';
const FORM_DATA_QUERY = gql`
  query GuestReservationFormData($reference: String, $token: String) {
    guestReservationFormData(where: { reference: $reference }, token: $token) {
      guestStaysAtThisProperty
      guestStaysAtOtherProperties
      firstName
      lastName
      primaryPhoneNumber
      platformKey
      propertyName
      propertyKey
      checkIn
      checkOut
      address1
      address2
      city
      state
      postalCode
      country

      property {
        type
        name
        ... on PropertyCombo {
          childProperties {
            name
            ... on Property {
              virtualTours {
                title
                url
              }
              floorPlanShareableUrl
              # floorPlan {
              #   fileInstance {
              #     file {
              #       fullUrl
              #     }
              #   }
              # }
            }
          }
        }
        ... on Property {
          virtualTours {
            title
            url
          }
          floorPlanShareableUrl
          # floorPlan {
          #   fileInstance {
          #     file {
          #       fullUrl
          #     }
          #   }
          # }
        }

        sitePage {
          featuredFileCollection {
            files {
              fullUrl
            }
          }
        }
      }
    }
  }
`;
const CREATE_ENTRY_MUTATION = gql`
  mutation CreateGuestReservationFormEntry(
    $data: GuestReservationFormEntryCreateInput!
    $token: String
  ) {
    createGuestReservationFormEntry(data: $data, token: $token) {
      id
    }
  }
`;

// primaryResidenceName: firstName + ' ' + lastName,
// address1: reservation.address1,
// // address2: reservation.address2,
// city: reservation.city,
// state: reservation.state,
// postalCode: reservation.postalCode,
// country: reservation.country,
const validationSchema = ({ platformKey }) => {
  let confirmationCheckBoxes = {
    //    videotour: Yup.string().required('Please confirm'),
    virtualtour: Yup.string().required(
      'Please confirm your ability to view the 3D tour(s)'
    ),
    // floorplan: Yup.string().required('Please confirm'),
    arrangementsReviewed: Yup.string().required('Please confirm'),
  };
  if (platformKey != 'airbnb') {
    confirmationCheckBoxes = {
      ...confirmationCheckBoxes,
      agreement: Yup.string().required('Please confirm'),
    };
    // agreement
  }
  return Yup.object().shape({
    primaryResidenceName: Yup.string().when('isPrimaryResidence', {
      is: 'NO',
      then: Yup.string().required('Please enter your full legal name'),
    }),
    address1: Yup.string().when('isPrimaryResidence', {
      is: 'NO',
      then: Yup.string().required('Street Address is required'),
    }),
    otherTransportServiceConfirmation: Yup.string().when(
      'otherTransportService',
      {
        is: val => {
          return val == 'YES' || val == 'UNSURE';
        },
        then: Yup.string().required(
          'Please confirm that you will communicate to all guests that early check-in is not available'
        ),
      }
    ),
    city: Yup.string().when('isPrimaryResidence', {
      is: 'NO',
      then: Yup.string().required('City is required'),
    }),
    state: Yup.string().when('isPrimaryResidence', {
      is: 'NO',
      then: Yup.string().required('State is required'),
    }),
    postalCode: Yup.string().when('isPrimaryResidence', {
      is: 'NO',
      then: Yup.string().required('Zip Code is required'),
    }),
    country: Yup.string().when('isPrimaryResidence', {
      is: 'NO',
      then: Yup.string().required('Country is required'),
    }),
    otherTransportService: Yup.string().required('Please select an option'),
    mobilityIssuesStaying: Yup.string().required('Please select an option'),
    mobilityIssuesVisiting: Yup.string().when('hasVisitors', {
      is: 'YES',
      then: Yup.string().required('Please select an option'),
    }),
    hasVisitors: Yup.string().required('Please select an option'),
    hasSpecialOccasion: Yup.string().required('Please select an option'),
    hasOtherReservations: Yup.string().required('Please select an option'),
    isPrimaryResidence: Yup.string().required('Please select an option'),
    ...confirmationCheckBoxes,
  });
};

const NotFoundPage = ({ pageContext, ...props }) => {
  // const formDataQuery = `guestReservationFormData(where: {reference: "HA-8X1SPK"}, token: "0362SEEtOFgxU1BL") {
  //     firstName
  //     platformKey
  //     propertyKey
  //   }
  // }`;
  console.log('props', props);
  const params = new URLSearchParams(props.location?.search);
  console.log('params', params.get('token'));
  console.log('reference', params.get('reference'));
  const reference = params.get('reference');
  const token = params.get('token');
  const [visitingHourGroups, setVisitingHourGroups] = useState(1);
  const [earlyCheckinModalOpen, setEarlyCheckinModalOpen] = useState(false);
  const [mobilityModalOpen, setMobilityModalOpen] = useState(false);
  const [showDebug, setShowDebug] = useState(false);

  const [formStatus, setFormStatus] = useState(null);
  const { data, error, loading, refetch } = useQuery(FORM_DATA_QUERY, {
    variables: { reference, token },
  }); //

  useEffect(() => {
    $('[data-toggle="popover"]').popover({
      container: 'body',
    });
    return () => {
      $('[data-toggle="popover"]').popover('dispose');
    };
  }, [loading]);

  const errorMessage = error?.message?.replace(/GraphQL error: /, '');
  const {
    firstName,
    primaryPhoneNumber,
    guestStaysAtThisProperty,
    guestStaysAtOtherProperties,
    lastName,
    platformKey,
    propertyName,
    ...reservation
  } = data?.guestReservationFormData || {};
  const AddressDisplay = () => {
    return (
      <pre className="mt-1plus">{`${firstName} ${lastName}
${reservation.address1}
${reservation.city} ${reservation.state} ${reservation.postalCode} 
${reservation.country || ''}
${primaryPhoneNumber || ''}`}</pre>
    );
  };
  console.log('reservation', reservation);
  const [
    createGuestReservationFormEntry,
    { data: submissionResult, loading: mutationLoading },
  ] = useMutation(CREATE_ENTRY_MUTATION);
  // const [mutationLoading, _] = useState(false);
  console.log('reservation', reservation);
  return loading ? (
    <div className="mt-3">
      <Loading />
    </div>
  ) : !reference || !token ? (
    <div className="mt-3 text-center">Missing Parameters</div>
  ) : errorMessage ? (
    <div className="mt-3 text-center">{errorMessage}</div>
  ) : (
    <Formik
      initialValues={{
        // formId: 1,
        // reference: reference,
        otherTransportService: '',
        mobilityIssuesStaying: '',
        mobilityIssuesVisiting: '',

        otherTransportServiceConfirmation: '',
        hasSpecialOccasion: '',
        hasVisitors: '',
        isPrimaryResidence: '',
        hasOtherReservations: '',
        isPrimaryResidence: reservation.address1 ? '' : 'NO',
        primaryResidenceName: firstName + ' ' + lastName,
        primaryPhoneNumber: primaryPhoneNumber,
        address1: reservation.address1 || '',
        videotour: '',
        virtualtour: '',
        arrangementsReviewed: '',
        floorplan: '',
        agreement: '',
        // address2: reservation.address2,
        city: reservation.city || '',
        state: reservation.state || '',
        postalCode: reservation.postalCode || '',
        country: reservation.country || '',
      }}
      validationSchema={validationSchema({ platformKey })}
      onSubmit={async (
        values,
        { setSubmitting, setStatus, setErrors, resetForm }
      ) => {
        console.log('values', values);
        try {
          createGuestReservationFormEntry({
            variables: {
              data: {
                formId: 1,
                reference: reference,
                payload: JSON.stringify(values),
              },
              token: token,
            },
          })
            .then(result => {
              // setSubmitting(false);
              //trackEvent('Forms', { formType: 'Request Info' });
              setFormStatus({ success: true });
              // resetForm();
              // setStartDate(defaultCheckInDate);
              // setEndDate(defaultCheckOutDate);
            })
            .catch(err => {
              console.log('err', err);
              setSubmitting(false);
              setStatus({
                error:
                  'There was a problem with your submission. Please check your data and try again.',
              });
              setErrors({
                message:
                  'There was a problem with your submission. Please check your data and try again.',
              });
            });
        } catch (err) {
          console.log('err', err);
          setSubmitting(false);
          setStatus({
            error:
              'There was a problem with your submission. Please check your data and try again.',
          });
        }
      }}
    >
      {formikProps => {
        const {
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          status,
          resetForm,
          submitCount,
          setStatus,
        } = formikProps;
        console.log('errors', errors);
        console.log('touched', touched);
        const formatList = listItems => {
          const last = listItems.pop();
          const result = listItems.join(', ') + ' & ' + last;
          return result;
        };
        return (
          <div>
            {params.get('debug') && (
              <>
                <button
                  className="btn btn-link"
                  style={{ position: 'absolute', top: '5px', right: '5px' }}
                  onClick={() => {
                    setShowDebug(!showDebug);
                  }}
                >
                  <Package width="20" height="20" />
                </button>
                {showDebug && (
                  <div>
                    <pre>
                      {JSON.stringify(
                        Object.keys(data.guestReservationFormData).reduce(
                          (acc, key) => {
                            if (
                              typeof data.guestReservationFormData[key] ===
                              'object' ||
                              key == '__typename'
                            ) {
                            } else {
                              acc[key] = data.guestReservationFormData[key];
                            }
                            console.log('acc', key);
                            return acc;
                          },
                          {}
                        ),
                        null,
                        2
                      )}
                    </pre>
                  </div>
                )}
              </>
            )}
            <form onSubmit={handleSubmit}>
              <SEO title="TYFR" description="TYFR" />
              <div className="container-small container mt-3">
                Hello {firstName ? firstName : 'Guest'},
                <br />
                <br />
                {guestStaysAtThisProperty || guestStaysAtOtherProperties ? (
                  <>
                    Thank you for booking a return trip to Beach Houses in
                    Paradise!
                    <br />
                    <br />"{propertyName}"{' '}
                    {reservation.property.type == 'COMBO' &&
                      ` (${formatList(
                        reservation.property.childProperties.map(
                          property => property.name
                        )
                      )})`}{' '}
                    is yours for the travel dates indicated on your reservation,{' '}
                    {new Date(reservation.checkIn).toLocaleDateString('en-US', {
                      timeZone: 'America/New_York',

                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}{' '}
                    -{' '}
                    {new Date(reservation.checkOut).toLocaleDateString(
                      'en-US',
                      {
                        timeZone: 'America/New_York',

                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                    )}
                    , and your reservation is booked.
                    <br />
                    <br />
                    To complete our file for your confirmed reservation, please
                    provide the following details:
                  </>
                ) : (
                  <>
                    Thank you for your pending reservation at "{propertyName}"
                    {reservation.property.type == 'COMBO' &&
                      ` (${formatList(
                        reservation.property.childProperties.map(
                          property => property.name
                        )
                      )})`}
                    , for{' '}
                    {new Date(reservation.checkIn).toLocaleDateString('en-US', {
                      timeZone: 'America/New_York',

                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}{' '}
                    -{' '}
                    {new Date(reservation.checkOut).toLocaleDateString(
                      'en-US',
                      {
                        timeZone: 'America/New_York',

                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }
                    )}{' '}
                    <br />
                    <br />
                    To confirm your reservation and secure your dates, the
                    reservation system requires some general information,
                    including the size of your group, visitor information (if
                    any) and your contact details. Please complete the following
                    information:
                  </>
                )}
                <h3 className="primary-bg rounded mt-3 px-2 py-1plus">
                  Guest Information
                </h3>
                <p className="mb-2">
                  Guests staying at the property. (This information does not
                  affect the rate, but it does help us to sufficiently stock the
                  home with supplies during your stay, and arrange for
                  additional parking.)
                </p>
                <div className="row">
                  <div className="col col-lg-8">
                    <table cellpadding="6" className="mb-3">
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <small className="d-flex align-items-center">
                              Confirmed Guests{' '}
                              <Info
                                className="ml-1"
                                width="16"
                                height="16"
                                data-trigger="hover"
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
                                data-content={`Please indicate the number of confirmed guests that will be attending, as of today.`}
                              />
                            </small>
                          </td>
                          <td>
                            <small className="d-flex align-items-center">
                              Tentative Guests{' '}
                              <Info
                                className="ml-1"
                                width="16"
                                height="16"
                                data-trigger="hover"
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
                                data-content={`Please indicate the number of extra guests that may be attending.`}
                              />
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>Number of Adults</td>
                          <td>
                            <div className="d-flex align-center align-items-center">
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="adultsStayingConfirmed"
                              />
                              <div style={{ marginLeft: '10px' }}>+</div>
                            </div>
                          </td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="#"
                              onChange={handleChange}
                              name="adultsStayingPossible"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Number of Children
                            <br />
                            (Ages 0-3)
                          </td>
                          <td>
                            <div className="d-flex align-center align-items-center">
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="toddlersStayingConfirmed"
                              />
                              <div style={{ marginLeft: '10px' }}>+</div>
                            </div>
                          </td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="#"
                              onChange={handleChange}
                              name="toddlersStayingPossible"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                              Number of Children
                            </span>
                            <br />
                            (Ages 4-17)
                          </td>
                          <td>
                            <div className="d-flex align-center align-items-center">
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="childrenStayingConfirmed"
                              />
                              <div style={{ marginLeft: '10px' }}>+</div>
                            </div>
                          </td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="#"
                              onChange={handleChange}
                              name="childrenStayingPossible"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Number of Pets</td>
                          <td>
                            <div className="d-flex align-center align-items-center">
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="petsStayingConfirmed"
                              />
                              <div style={{ marginLeft: '10px' }}>+</div>
                            </div>
                          </td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="#"
                              onChange={handleChange}
                              name="petsStayingPossible"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Number of Vehicles</td>
                          <td>
                            <div className="d-flex align-center align-items-center">
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="vehiclesStayingConfirmed"
                              />
                              <div style={{ marginLeft: '10px' }}>+</div>
                            </div>
                          </td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="#"
                              onChange={handleChange}
                              name="vehiclesStayingPossible"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <p className="mb-2">
                              Does anyone in your group require help moving
                              around the property due to mobility issues or
                              similar concerns?
                            </p>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="mobilityIssuesStaying1"
                                name="mobilityIssuesStaying"
                                className="custom-control-input"
                                value="YES"
                                onChange={e => {
                                  setMobilityModalOpen(true);
                                  handleChange(e);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                for="mobilityIssuesStaying1"
                              >
                                {' '}
                                Yes{' '}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="mobilityIssuesStaying2"
                                name="mobilityIssuesStaying"
                                value="NO"
                                className="custom-control-input"
                                onChange={e => {
                                  console.log('NO value change');
                                  handleChange(e);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                for="mobilityIssuesStaying2"
                              >
                                {' '}
                                No{' '}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="mobilityIssuesStaying3"
                                name="mobilityIssuesStaying"
                                value="UNSURE"
                                className="custom-control-input"
                                onChange={e => {
                                  console.log('UNSURE value change');
                                  setMobilityModalOpen(true);
                                  handleChange(e);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                for="mobilityIssuesStaying3"
                              >
                                {' '}
                                I don't know yet{' '}
                              </label>
                            </div>
                            <div className="small text-danger">
                              {touched.mobilityIssuesStaying &&
                                errors.mobilityIssuesStaying &&
                                errors.mobilityIssuesStaying}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <p className="mb-2">
                              On the day of check-in, will anybody be arriving
                              via uber, lyft, taxi, shuttle, towncar or other
                              transport service?
                            </p>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="otherTransportService1"
                                name="otherTransportService"
                                className="custom-control-input"
                                value="YES"
                                onChange={e => {
                                  setEarlyCheckinModalOpen(true);
                                  handleChange(e);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                for="otherTransportService1"
                              >
                                {' '}
                                Yes{' '}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="otherTransportService2"
                                name="otherTransportService"
                                value="NO"
                                className="custom-control-input"
                                onChange={e => {
                                  console.log('NO value change');
                                  handleChange(e);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                for="otherTransportService2"
                              >
                                {' '}
                                No{' '}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="otherTransportService3"
                                name="otherTransportService"
                                value="UNSURE"
                                className="custom-control-input"
                                onChange={e => {
                                  console.log('UNSURE value change');
                                  setEarlyCheckinModalOpen(true);
                                  handleChange(e);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                for="otherTransportService3"
                              >
                                {' '}
                                I don't know yet{' '}
                              </label>
                            </div>
                            <div className="small text-danger">
                              {touched.otherTransportService &&
                                errors.otherTransportService &&
                                errors.otherTransportService}
                            </div>
                            <Modal
                              isOpen={mobilityModalOpen}
                              title={'Mobility notice'}
                              onClose={() => setMobilityModalOpen(false)}
                              disableFocusTrap={true}
                            >
                              Whenever there's a guest staying at -- or visiting
                              -- one of our homes that might have difficulty
                              with mobility, you may want to consider taking
                              advantage of a home healthcare aid that can
                              provide another set of eyes, and helpful support.
                              If you would like me to make any arrangements,
                              please let me know. (An additional hourly fee
                              would be involved)
                              <br />
                              <br />
                              <button
                                className="btn btn-primary d-block mx-auto"
                                onClick={() => setMobilityModalOpen(false)}
                              >
                                Close
                              </button>
                            </Modal>
                            <Modal
                              isOpen={earlyCheckinModalOpen}
                              title={'Early check-in notice'}
                              onClose={() => setEarlyCheckinModalOpen(false)}
                              disableFocusTrap={true}
                            >
                              Please note that early check-in is unavailable to
                              everyone in your party, unless by prior
                              arrangement with Al Roberts.
                              <br />
                              <br />
                              For example:
                              <br />
                              <br />
                              If one of your guests is dropped before the
                              offical check-in time by uber, taxi, shuttle or
                              other transport service, that guest has nowhere to
                              go, and nothing to do until the check-in time.
                              (S)he can neither enter the premises nor be
                              anywhere on the property -- including the
                              oceanfront and riverfront beaches -- prior to the
                              official check-in time. No bathroom facilities,
                              umbrellas, parking spaces or other amenities are
                              available prior to check-in.
                              <br />
                              <br />
                              <button
                                className="btn btn-primary d-block mx-auto"
                                onClick={() => setEarlyCheckinModalOpen(false)}
                              >
                                Close
                              </button>
                            </Modal>
                            {['YES', 'UNSURE'].includes(
                              values.otherTransportService
                            ) && (
                                <div className="form-group mt-2 mb-0">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      id="otherTransportServiceConfirmation"
                                      name="otherTransportServiceConfirmation"
                                      className="custom-control-input"
                                      value="YES"
                                      onChange={handleChange}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for="otherTransportServiceConfirmation"
                                    >
                                      I will advise any guests contemplating
                                      arrival via uber, taxi, shuttle or other
                                      transport service that the property is
                                      completely unavailable until the official
                                      check-in time.
                                    </label>
                                  </div>
                                  <div
                                    className="small text-danger"
                                    style={{ marginLeft: '1.5rem' }}
                                  >
                                    {touched.otherTransportServiceConfirmation &&
                                      errors.otherTransportServiceConfirmation &&
                                      errors.otherTransportServiceConfirmation}
                                  </div>
                                </div>
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3" className="pt-2">
                            Any boats/watercraft, large vehicles, RVs or boat
                            trailers? (if so, please describe)
                            <textarea
                              className="form-control"
                              onChange={handleChange}
                              name="largeVehiclesStaying"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3" className="pt-2">
                            Please provide any additional details about guests
                            staying at the property.
                            <textarea
                              className="form-control"
                              onChange={handleChange}
                              name="additionDetailsStaying"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h3 className="primary-bg rounded px-2 py-1plus">
                  Visitor Information
                </h3>
                <p className="mb-2">
                  Are there guests visiting the property, but not staying
                  overnight? (This information does not affect the rate, but it
                  does help us to sufficiently stock the home with supplies
                  during your stay, and arrange for additional parking.)
                </p>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="hasVisitors1"
                    name="hasVisitors"
                    className="custom-control-input"
                    value="YES"
                    onChange={handleChange}
                  />
                  <label className="custom-control-label" for="hasVisitors1">
                    {' '}
                    Yes{' '}
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="hasVisitors2"
                    name="hasVisitors"
                    value="NO"
                    className="custom-control-input"
                    onChange={handleChange}
                  />
                  <label className="custom-control-label" for="hasVisitors2">
                    {' '}
                    No{' '}
                  </label>
                </div>
                <div className="small text-danger">
                  {touched.hasVisitors &&
                    errors.hasVisitors &&
                    errors.hasVisitors}
                </div>
                <div
                  style={{ display: values.hasVisitors == 'YES' ? '' : 'none' }}
                >
                  <div className="row">
                    <div className="col col-lg-8">
                      <table cellpadding="6">
                        <tbody>
                          <tr>
                            <td />
                            <td>
                              <small className="d-flex align-items-center">
                                Confirmed Visitors{' '}
                                <Info
                                  className="ml-1"
                                  width="16"
                                  height="16"
                                  data-trigger="hover"
                                  data-container="body"
                                  data-toggle="popover"
                                  data-placement="top"
                                  data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
                                  data-content={`Please indicate the number of confirmed visitors that will be attending, as of today.`}
                                />
                              </small>
                            </td>
                            <td>
                              <small className="d-flex align-items-center">
                                Tentative Visitors{' '}
                                <Info
                                  className="ml-1"
                                  width="16"
                                  height="16"
                                  data-trigger="hover"
                                  data-container="body"
                                  data-toggle="popover"
                                  data-placement="top"
                                  data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
                                  data-content={`Please indicate the number of extra visitors that may be attending.`}
                                />
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td>Number of Adults</td>
                            <td>
                              <div className="d-flex align-center align-items-center">
                                <input
                                  className="form-control"
                                  placeholder="#"
                                  onChange={handleChange}
                                  name="adultsVisitingConfirmed"
                                />
                                <div style={{ marginLeft: '10px' }}>+</div>
                              </div>
                            </td>
                            <td>
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="adultsVisitingPossible"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Number of Children
                              <br />
                              (Ages 0-3)
                            </td>
                            <td>
                              <div className="d-flex align-center align-items-center">
                                <input
                                  className="form-control"
                                  placeholder="#"
                                  onChange={handleChange}
                                  name="toddlersVisitingConfirmed"
                                />
                                <div style={{ marginLeft: '10px' }}>+</div>
                              </div>
                            </td>
                            <td>
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="toddlersVisitingPossible"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span style={{ whiteSpace: 'nowrap' }}>
                                Number of Children
                              </span>
                              <br />
                              (4-17)
                            </td>
                            <td>
                              <div className="d-flex align-center align-items-center">
                                <input
                                  className="form-control"
                                  placeholder="#"
                                  onChange={handleChange}
                                  name="childrenVisitingConfirmed"
                                />
                                <div style={{ marginLeft: '10px' }}>+</div>
                              </div>
                            </td>
                            <td>
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="childrenVisitingPossible"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Number of Pets</td>
                            <td>
                              <div className="d-flex align-center align-items-center">
                                <input
                                  className="form-control"
                                  placeholder="#"
                                  onChange={handleChange}
                                  name="petsVisitingConfirmed"
                                />
                                <div style={{ marginLeft: '10px' }}>+</div>
                              </div>
                            </td>
                            <td>
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="petsVisitingPossible"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Number of Vehicles</td>
                            <td>
                              <div className="d-flex align-center align-items-center">
                                <input
                                  className="form-control"
                                  placeholder="#"
                                  onChange={handleChange}
                                  name="vehiclesVisitingConfirmed"
                                />
                                <div style={{ marginLeft: '10px' }}>+</div>
                              </div>
                            </td>
                            <td>
                              <input
                                className="form-control"
                                placeholder="#"
                                onChange={handleChange}
                                name="vehiclesVisitingPossible"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <p className="mb-2">
                                Does anyone in your group require help moving
                                around the property due to mobility issues or
                                similar concerns?
                              </p>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="mobilityIssuesVisiting1"
                                  name="mobilityIssuesVisiting"
                                  className="custom-control-input"
                                  value="YES"
                                  onChange={e => {
                                    setMobilityModalOpen(true);
                                    handleChange(e);
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  for="mobilityIssuesVisiting1"
                                >
                                  {' '}
                                  Yes{' '}
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="mobilityIssuesVisiting2"
                                  name="mobilityIssuesVisiting"
                                  value="NO"
                                  className="custom-control-input"
                                  onChange={e => {
                                    console.log('NO value change');
                                    handleChange(e);
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  for="mobilityIssuesVisiting2"
                                >
                                  {' '}
                                  No{' '}
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline">
                                <input
                                  type="radio"
                                  id="mobilityIssuesVisiting3"
                                  name="mobilityIssuesVisiting"
                                  value="UNSURE"
                                  className="custom-control-input"
                                  onChange={e => {
                                    console.log('UNSURE value change');
                                    setMobilityModalOpen(true);
                                    handleChange(e);
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  for="mobilityIssuesVisiting3"
                                >
                                  {' '}
                                  I don't know yet{' '}
                                </label>
                              </div>
                              <div className="small text-danger">
                                {touched.mobilityIssuesVisiting &&
                                  errors.mobilityIssuesVisiting &&
                                  errors.mobilityIssuesVisiting}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" className="pt-2">
                              Any boats/watercraft, large vehicles, RVs or boat
                              trailers? (if so, please describe)
                              <textarea
                                className="form-control"
                                onChange={handleChange}
                                name="largeVehiclesVisiting"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="pt-3 pb-2">
                        When are you expecting additional visitors?
                      </div>
                      {Array.from({ length: visitingHourGroups }).map(
                        (_, index) => {
                          return (
                            <>
                              <div>
                                <table
                                  cellpadding="6"
                                  className={
                                    index + 1 != visitingHourGroups
                                      ? 'mb-1'
                                      : null
                                  }
                                >
                                  <tbody>
                                    <tr>
                                      <td />
                                      <td>Date</td>
                                      <td>Time</td>
                                    </tr>
                                    <tr>
                                      <td>Start</td>
                                      <td>
                                        <input
                                          className="form-control"
                                          placeholder="mm/dd/yy"
                                          onChange={handleChange}
                                          name={`visitorStartDate_${index + 1}`}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="form-control"
                                          placeholder="hh:mm"
                                          onChange={handleChange}
                                          name={`visitorStartTime_${index + 1}`}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>End</td>
                                      <td>
                                        <input
                                          className="form-control"
                                          placeholder="mm/dd/yy"
                                          onChange={handleChange}
                                          name={`visitorEndDate_${index + 1}`}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="form-control"
                                          placeholder="hh:mm"
                                          onChange={handleChange}
                                          name={`visitorEndTime_${index + 1}`}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                {index + 1 == visitingHourGroups && (
                                  <div>
                                    <button
                                      className="btn btn-link"
                                      onClick={() => {
                                        setVisitingHourGroups(
                                          visitingHourGroups + 1
                                        );
                                      }}
                                    >
                                      <img
                                        src={'/plus.svg'}
                                        alt="Plus"
                                        // id={`datepicker-next-button${id}`}
                                        width="20"
                                        height="20"
                                        className="no-resize counter-button"
                                      />{' '}
                                      Add visiting hours
                                    </button>
                                    {index > 0 && (
                                      <button
                                        className="btn btn-link text-red"
                                        onClick={() => {
                                          setVisitingHourGroups(
                                            visitingHourGroups - 1
                                          );
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 40 40"
                                        >
                                          <g
                                            id="minus"
                                            transform="translate(1169 -2247)"
                                          >
                                            <g
                                              id="Rectangle_537"
                                              data-name="Rectangle 537"
                                              transform="translate(-1169 2247)"
                                              fill="#fff"
                                              stroke="red"
                                              stroke-width="1.5"
                                            >
                                              <rect
                                                width="20"
                                                height="20"
                                                rx="20"
                                                stroke="none"
                                              />
                                              <rect
                                                x="0.75"
                                                y="0.75"
                                                width="38.5"
                                                height="38.5"
                                                rx="19.25"
                                                fill="none"
                                              />
                                            </g>
                                            <rect
                                              id="Rectangle_538"
                                              data-name="Rectangle 538"
                                              width="1.5"
                                              height="16"
                                              transform="translate(-1141 2266) rotate(90)"
                                              fill="red"
                                            />
                                          </g>
                                        </svg>{' '}
                                        Remove visiting hours
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        }
                      )}
                      <div className="row mt-2">
                        <div className="col">
                          Please provide any available details about visitors
                          and visiting hours to the property.
                          <textarea
                            className="form-control"
                            onChange={handleChange}
                            name="additionalDetailsGuestsVisiting"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="primary-bg rounded px-2 py-1plus mt-3">
                  Special Occasions
                </h3>
                <p className="mb-2">
                  Are you celebrating or hosting a special occasion at the
                  property, or attending a local event? (If so, I’m here to
                  ensure that it is a success.)
                </p>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="hasSpecialOccasion1"
                    name="hasSpecialOccasion"
                    className="custom-control-input"
                    value="YES"
                    onChange={handleChange}
                  />
                  <label
                    className="custom-control-label"
                    for="hasSpecialOccasion1"
                  >
                    {' '}
                    Yes{' '}
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="hasSpecialOccasion2"
                    name="hasSpecialOccasion"
                    value="NO"
                    className="custom-control-input"
                    onChange={handleChange}
                  />
                  <label
                    className="custom-control-label"
                    for="hasSpecialOccasion2"
                  >
                    {' '}
                    No{' '}
                  </label>
                </div>{' '}
                <div className="small text-danger">
                  {touched.hasSpecialOccasion &&
                    errors.hasSpecialOccasion &&
                    errors.hasSpecialOccasion}
                </div>
                <div
                  style={{
                    display: values.hasSpecialOccasion == 'YES' ? '' : 'none',
                  }}
                >
                  <div className="pt-2">
                    Please provide all available details about the event or
                    celebration:
                  </div>
                  <div>
                    <textarea
                      className="form-control"
                      onChange={handleChange}
                      name="specialOccasionDetails"
                    />
                  </div>
                </div>
                <h3 className="primary-bg rounded px-2 py-1plus mt-3">
                  Other Reservations
                </h3>
                <p className="mb-2">
                  Are you involved with a larger group that has other
                  reservations with BHIP or other area lodging establishments?
                  (If so, we can help coordinate and provide better service.)
                </p>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="hasOtherReservations1"
                    name="hasOtherReservations"
                    className="custom-control-input"
                    value="YES"
                    onChange={handleChange}
                  />
                  <label
                    className="custom-control-label"
                    for="hasOtherReservations1"
                  >
                    {' '}
                    Yes{' '}
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="hasOtherReservations2"
                    name="hasOtherReservations"
                    value="NO"
                    className="custom-control-input"
                    onChange={handleChange}
                  />
                  <label
                    className="custom-control-label"
                    for="hasOtherReservations2"
                  >
                    {' '}
                    No{' '}
                  </label>
                </div>{' '}
                <div className="small text-danger">
                  {touched.hasOtherReservations &&
                    errors.hasOtherReservations &&
                    errors.hasOtherReservations}
                </div>
                <div
                  style={{
                    display: values.hasOtherReservations == 'YES' ? '' : 'none',
                  }}
                >
                  <div className="pt-2">
                    Please provide all available details about other
                    reservations:
                  </div>
                  <div>
                    <textarea
                      className="form-control"
                      onChange={handleChange}
                      name="otherReservationsDetails"
                    />
                  </div>
                </div>
                <h3 className="primary-bg rounded px-2 py-1plus mt-3">
                  Contact Information
                </h3>
                <p className="mb-2">
                  Is your primary residential address correct? (This is a legal
                  requirement under the owner's insurance coverage, and during
                  COVID-19 for use in statistical reporting and infection
                  tracing by the regional health & tourism authority.)
                </p>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="isPrimaryResidence1"
                    name="isPrimaryResidence"
                    className="custom-control-input"
                    value="YES"
                    checked={values.isPrimaryResidence == 'YES'}
                    onChange={handleChange}
                  />
                  <label
                    className="custom-control-label"
                    for="isPrimaryResidence1"
                  >
                    {' '}
                    Yes{' '}
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="isPrimaryResidence2"
                    name="isPrimaryResidence"
                    value="NO"
                    checked={values.isPrimaryResidence == 'NO'}
                    className="custom-control-input"
                    onChange={handleChange}
                  />
                  <label
                    className="custom-control-label"
                    for="isPrimaryResidence2"
                  >
                    {' '}
                    No{' '}
                  </label>
                </div>
                <div className="small text-danger">
                  {touched.isPrimaryResidence &&
                    errors.isPrimaryResidence &&
                    errors.isPrimaryResidence}
                </div>
                {values.isPrimaryResidence == 'NO' ? (
                  <div className="row">
                    <div className="col col-lg-5">
                      <div className="form-group mt-2">
                        <label htmlFor="primaryResidenceName">
                          <b>Name</b>
                        </label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="primaryResidenceName"
                          className="form-control"
                          defaultValue={values.primaryResidenceName}
                        />
                        <div className="small text-danger mt-1">
                          {touched.primaryResidenceName &&
                            errors.primaryResidenceName &&
                            errors.primaryResidenceName}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="address1">
                          <b>Street Address</b>
                        </label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="address1"
                          className="form-control"
                          defaultValue={values.address1}
                        />
                        <div className="small text-danger mt-1">
                          {touched.address1 &&
                            errors.address1 &&
                            errors.address1}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="seoDescription">
                          <b>City</b>
                        </label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="city"
                          className="form-control"
                          defaultValue={values.city}
                        />
                        <div className="small text-danger mt-1">
                          {touched.city && errors.city && errors.city}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="seoDescription">
                          <b>State</b>
                        </label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="state"
                          className="form-control"
                          defaultValue={values.state}
                        />
                        <div className="small text-danger mt-1">
                          {touched.state && errors.state && errors.state}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="seoDescription">
                          <b>Zip Code</b>
                        </label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="postalCode"
                          className="form-control"
                          defaultValue={values.postalCode}
                        />
                        <div className="small text-danger mt-1">
                          {touched.postalCode &&
                            errors.postalCode &&
                            errors.postalCode}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="country">
                          <b>Country</b>
                        </label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="country"
                          className="form-control"
                          defaultValue={values.country}
                        />
                        <div className="small text-danger mt-1">
                          {touched.country && errors.country && errors.country}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="primaryPhoneNumber">
                          <b>Phone Number</b>
                        </label>
                        <input
                          type="text"
                          onChange={handleChange}
                          name="primaryPhoneNumber"
                          className="form-control"
                          defaultValue={values.primaryPhoneNumber}
                        />
                        <div className="small text-danger mt-1">
                          {touched.primaryPhoneNumber &&
                            errors.primaryPhoneNumber &&
                            errors.primaryPhoneNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <AddressDisplay />
                  </>
                )}
                <h3 className="primary-bg rounded px-2 py-1plus">
                  Additional Resources
                </h3>
                <div>
                  This helps you explore the property, plan sleeping
                  arrangements, and more:
                </div>
                <div className="form-group mt-2">
                  {/* <label htmlFor="name">
                  <b>Check All</b>
                  <span className="text-danger ml-1">*</span>
                </label> */}

                  <div className="form-check custom-control custom-checkbox">
                    <input
                      className="form-check-input custom-control-input"
                      type="checkbox"
                      value="Y"
                      id="floorplan"
                      name="floorplan"
                      onChange={handleChange}
                    />
                    <label
                      className={`form-check-label custom-control-label`}
                      htmlFor="floorplan"
                    >
                      <p className="mb-2">
                        I am able to view the floor plan(s) at:
                      </p>

                      {reservation.property.type === 'COMBO' ? (
                        <>
                          {reservation.property.childProperties.map(
                            (property, index) => {
                              return (
                                <>
                                  {index > 0 && <br />}
                                  {property.name}:{' '}
                                  <a
                                    href={property.floorPlanShareableUrl}
                                    target="_blank"
                                  >
                                    {property.floorPlanShareableUrl}
                                  </a>
                                  <br />
                                </>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <>
                          <a
                            href={reservation.property.floorPlanShareableUrl}
                            target="_blank"
                          >
                            {reservation.property.floorPlanShareableUrl}
                          </a>
                        </>
                      )}
                    </label>
                    <div className="small text-danger mt-1">
                      {touched.floorplan &&
                        errors.floorplan &&
                        errors.floorplan}
                    </div>
                  </div>

                  <div className="form-group mt-2">
                    <div className="form-check custom-control custom-checkbox">
                      <input
                        className="form-check-input custom-control-input"
                        type="checkbox"
                        value="Y"
                        id="videotour"
                        name="videotour"
                        onChange={handleChange}
                      />
                      <label
                        className={`form-check-label custom-control-label`}
                        htmlFor="videotour"
                      >
                        <p className="mb-2">
                          I am able to watch the video tour at:
                        </p>
                        {reservation.property.name}:{' '}
                        <a
                          href={
                            reservation.property.sitePage.featuredFileCollection
                              .files[0].fullUrl
                          }
                          target="_blank"
                        >
                          {
                            reservation.property.sitePage.featuredFileCollection
                              .files[0].fullUrl
                          }
                        </a>
                      </label>
                      <div className="small text-danger mt-1">
                        {touched.videotour &&
                          errors.videotour &&
                          errors.videotour}
                      </div>
                    </div>
                    <div className="my-2">
                      <p className="mb-2">
                        <b>Choose one</b>:
                      </p>
                      <div className="ml-3">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="virtualtour1"
                            name="virtualtour"
                            className="custom-control-input"
                            value="Y"
                            onChange={handleChange}
                          />
                          <label
                            className="custom-control-label"
                            for="virtualtour1"
                          >
                            I am able to navigate the 3D tour(s) at:
                          </label>
                        </div>
                        <div className="ml-3 my-2">
                          {reservation.property.type === 'COMBO' ? (
                            <>
                              {reservation.property.childProperties.map(
                                (property, index) => {
                                  return property.virtualTours.map(
                                    virtualTour => {
                                      return (
                                        <>
                                          {index > 0 && <br />}
                                          {property.name} - {virtualTour.title}:{' '}
                                          <a
                                            href={virtualTour.url}
                                            target="_blank"
                                          >
                                            {virtualTour.url}
                                          </a>
                                          <br />
                                        </>
                                      );
                                    }
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <>
                              {reservation.property.virtualTours.map(
                                (virtualTour, index) => {
                                  return (
                                    <>
                                      {index > 0 && <br />}
                                      {virtualTour.title}:{' '}
                                      <a href={virtualTour.url} target="_blank">
                                        {virtualTour.url}
                                      </a>
                                      <br />
                                    </>
                                  );
                                }
                              )}
                            </>
                          )}
                        </div>

                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="virtualtour2"
                            name="virtualtour"
                            value="Y"
                            className="custom-control-input"
                            onChange={handleChange}
                          />
                          <label
                            className="custom-control-label"
                            for="virtualtour2"
                          >
                            I am unable to view the 3D tour(s)
                          </label>
                        </div>
                        <div className="small text-danger">
                          {touched.virtualtour &&
                            errors.virtualtour &&
                            errors.virtualtour}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-check custom-control custom-checkbox mt-2">
                    <input
                      className="form-check-input custom-control-input"
                      type="checkbox"
                      value="Y"
                      id="arrangementsReviewed"
                      name="arrangementsReviewed"
                      onChange={handleChange}
                    />
                    <label
                      className={`form-check-label custom-control-label`}
                      htmlFor="arrangementsReviewed"
                    >
                      I have reviewed the above house tools and have started
                      planning sleeping arrangements. I confirm that this
                      property will work for my group's needs.
                    </label>
                    <div className="small text-danger mt-1">
                      {touched.arrangementsReviewed &&
                        errors.arrangementsReviewed &&
                        errors.arrangementsReviewed}
                    </div>
                  </div>

                  {platformKey != 'airbnb' && (
                    <div className="form-check custom-control custom-checkbox mt-2">
                      <input
                        className="form-check-input custom-control-input"
                        type="checkbox"
                        value="Y"
                        id="agreement"
                        name="agreement"
                        onChange={handleChange}
                      />
                      <label
                        className={`form-check-label custom-control-label`}
                        htmlFor="agreement"
                      >
                        I have read and agree to the user agreement at{' '}
                        <a
                          href="https://images.beachhousesinparadise.com/legal/bhip-lease-agreement.pdf"
                          target="_blank"
                          className="force-break"
                        >
                          https://images.beachhousesinparadise.com/legal/bhip-lease-agreement.pdf
                        </a>
                      </label>
                      <div className="small text-danger mt-1">
                        {touched.agreement &&
                          errors.agreement &&
                          errors.agreement}
                      </div>
                    </div>
                  )}
                  {/* <div className="form-check custom-control custom-checkbox">
                    <input
                      className="form-check-input custom-control-input"
                      type="checkbox"
                      value="Y"
                      id="agreement"
                      name="agreement"
                    />
                    <label
                      className={`form-check-label custom-control-label`}
                      htmlFor="agreement"
                    >
                      I have read and agree to the user agreement at {reservation.property.floorPlan.fileInstance.file.fullUrl}
                    </label>
                  </div> */}
                </div>
                {platformKey == 'airbnb' && (
                  <>
                    <h3 className="primary-bg rounded mt-3 px-2 py-1plus">
                      Rental Agreement
                    </h3>
                    <div>
                      Please review and agree to the standard terms & conditions
                      for your reservation through the link below so I can
                      finalize your reservation. The agreement describes the
                      duties and responsibilities of both Beach Houses in
                      Paradise and yourself (and your party):
                      <br />
                      <br />
                      <a
                        href="https://app.signaturely.com/form-requests/19f71828-278e-4bf8-8120-6d62c9373538/send"
                        target="_blank"
                        className="force-break"
                      >
                        https://app.signaturely.com/form-requests/19f71828-278e-4bf8-8120-6d62c9373538/send
                      </a>
                      <br />
                      <br />
                      And the lease agreement will be email-verified by the
                      Signaturely e-signature service (noreply@signaturely.com)
                      <br />
                      <br />
                    </div>
                  </>
                )}
                <FormFeedback
                  errors={status?.error}
                  isVisible={
                    status?.error ||
                    formStatus?.success ||
                    (!objectIsEmpty(errors) && submitCount > 0)
                  }
                  alert={!objectIsEmpty(errors) && submitCount > 0}
                >
                  Thank you for this information. We will be in touch shortly.
                </FormFeedback>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn btn-dark font-weight-bold rounded w-100  mb-5"
                >
                  {!mutationLoading && <span>Submit Information</span>}
                  {mutationLoading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </form>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        );
      }}
    </Formik>
  );
};

export default NotFoundPage;
