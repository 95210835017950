import React, { useState, useEffect, useRef } from 'react';
import ReactCalendar from '../ReactCalendar';
import Loading, { Dots } from '../Loading';
import PropertyBookingCalendar from '../PropertyBookingCalendar';
import Modal from '../Modal';
import {
  formatDateWithDashes,
  getDatesInMonth,
  monthNames,
  dayNames,
  months,
  addMonth,
  //   subtractMonth,
} from '../../util/dateHelper';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { ChevronLeft, ChevronRight } from 'react-feather';
import './styles.scss';
import { css } from 'styled-components';

const CALENDAR_DATA = gql`
  query CalendarData($id: Int) {
    property(where: { id: $id }) {
      key
      masterPlatformListing {
        bookingWindowInDays
      }		
      calendar {
        start
        end
        availability
        minStay
        minStayThresholdAlert
      }
    }
  }
`;
const DayCell = ({
  onClick,
  dayInMonth,
  classList,
  index,

  onMouseEnter = () => { },
}) => {
  const cellForTooltip = useRef(null);
  const cellWithMinStayDots = classList.find(cell => {
    if (cell === 'min-stay-dots') {
      return cell;
    }
  });

  useEffect(() => {
    if (cellWithMinStayDots) {
      $(cellForTooltip.current).tooltip({
        template: `<div class="tooltip single-property calendar-tooltip text-left" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>`,
        name: 'flip',
        html: true,
        title:
          '<span>This property is subject to minimum-stay requirements, depending on season and availability.<span> <br> <br> <span>When this home is vacant for shorter periods, the owner takes advantage of these brief vacancies to schedule maintenance and improvements.</span>',
        placement: 'bottom',
        modifiers: {
          name: 'preventOverflow',
          options: {
            placement: 'bottom-start',
          },
        },
      });
    }
    return () => {
      if (cellForTooltip?.current && cellWithMinStayDots) {
        $(cellForTooltip.current).tooltip('dispose');
      }
    };
  }, [classList]);
  return (
    <div
      ref={cellForTooltip}
      data-date={formatDateWithDashes(dayInMonth)}
      className={`${classList.join(' ')} d-flex align-items-center`}
      onClick={onClick}
      key={`day_${index}`}
      onMouseEnter={e => {
        onMouseEnter(e);
      }}
    >
      <span className="m-auto">{index + 1}</span>
    </div>
  );
};

const SinglePropertyAvailabilityCalendar = ({ propertyId, allowBooking, promo }) => {

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();
  const [monthCount, setMonthCount] = useState(0);
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [dateForDisplay, setDateForDisplay] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );

  return (
    <Query query={CALENDAR_DATA} variables={{ id: propertyId }}>
      {({ data, loading, error }) => {
        const calendar = data?.property?.calendar || {};

        const maxDate = calendar?.end ? new Date(calendar?.end) : '+2Y';
        const bookingWindowInDays = data?.property?.masterPlatformListing?.bookingWindowInDays ? data?.property?.masterPlatformListing?.bookingWindowInDays : 5;




        const minStayAlertDays = (calendar?.minStayThresholdAlert || '').split(
          ''
        );
        const availableDays = (calendar?.availability || '').split('');
        const minStayDays = (calendar?.minStay || '').split(',');
        const calendarStartDate = calendar?.start
          ? new Date(calendar?.start)
          : new Date();

        //        console.log('calendarStartDate', calendarStartDate)
        const bookingAllowedStartDate = new Date(calendarStartDate).setDate(new Date(calendarStartDate).getDate() + bookingWindowInDays);
        // console.log('calendarStartDate2', calendarStartDate)
        // console.log('bookingAllowedStartDate', new Date(bookingAllowedStartDate))


        const yearRange = Array.from({
          length:
            calendar && calendar.length > 0
              ? new Date(calendar?.end).getFullYear() -
              new Date(calendar?.start).getFullYear()
              : 2,
        }).length;
        const prevIsDisabled =
          selectedMonth === currentMonth + 1 && selectedYear === currentYear
            ? true
            : false;
        const nextIsDisabled =
          selectedMonth === new Date(calendar?.end || '').getMonth() + 1 &&
            selectedYear === new Date(calendar?.end || '').getFullYear()
            ? true
            : false;

        return (
          <>

            <div id="single-property-availability-calendar">
              <div
                className={`mx-auto mb-0  pt-1 pt-md-0 pb-1 d-flex justify-content-between text-center date-navigation`}
              >
                <button
                  id="prev-month"
                  // disabled={prevIsDisabled}
                  onClick={() => {
                    if (prevIsDisabled) {
                      return;
                    }
                    const currentMonth = dateForDisplay.getMonth();

                    const updatedDate = new Date(dateForDisplay);
                    updatedDate.setMonth(currentMonth - 1);
                    setDateForDisplay(updatedDate);

                    currentMonth === 0
                      ? setSelectedMonth(12)
                      : setSelectedMonth(currentMonth);

                    if (currentMonth === 0) {
                      setSelectedYear(selectedYear - 1);
                    }
                  }}
                  className={`${prevIsDisabled ? 'disable-navigation' : ''
                    } btn btn-link p-0`}
                >
                  <img
                    className="no-resize"
                    src="/previous.svg"
                    alt="Previous"
                    width="30"
                    height="40"
                  />
                </button>
                <label
                  //htmlFor={`${styles.monthSelect}${id}`}
                  className="sr-only"
                >
                  Month
                </label>
                <select
                  id="datepicker-month-select"
                  className={
                    'custom-select-list calendar border-0 font-weight-bold text-primary px-2'
                  }
                  value={selectedMonth}
                  onChange={e => {
                    const monthMumber = Number(e.target.value);
                    setSelectedMonth(monthMumber);
                    const updatedDate = new Date(dateForDisplay);

                    if (selectedYear !== updatedDate.getFullYear()) {
                      updatedDate.setFullYear(selectedYear);
                    }
                    updatedDate.setMonth(monthMumber - 1);
                    setDateForDisplay(updatedDate);
                  }}
                >
                  {months.reduce((acc, month, index) => {
                    const option = (
                      <option
                        key={month.value}
                        value={month.value}
                        hidden={
                          selectedYear === currentYear &&
                          month.value < currentMonth + 1
                        }
                      >
                        {month.label}
                      </option>
                    );

                    if (currentYear + yearRange > selectedYear) {
                      acc.push(option);
                    } else if (month.value <= currentMonth + 1) {
                      acc.push(option);
                    }

                    return acc;
                  }, [])}
                </select>
                <label
                  // htmlFor={`${styles.yearSelect}`}
                  className="sr-only"
                >
                  Year
                </label>
                <select
                  // style={{ width: '80px' }}

                  className="custom-select-list calendar border-0 font-weight-bold text-primary px-2 year-select"
                  value={selectedYear}
                  onChange={e => {
                    const updatedYear = Number(e.target.value);
                    setSelectedYear(updatedYear);
                    const updatedDate = new Date(dateForDisplay);
                    const monthSelectedIsPriorToCurrentMonth = () => {
                      return (
                        updatedYear === currentYear &&
                        selectedMonth < currentMonth + 1
                      );
                    };
                    const monthSelectedIsAfterDateRange = () => {
                      return (
                        updatedYear === currentYear + yearRange &&
                        selectedMonth > currentMonth + 1
                      );
                    };
                    if (monthSelectedIsPriorToCurrentMonth()) {
                      updatedDate.setMonth(currentMonth);
                      setSelectedMonth(addMonth(currentMonth));
                    }
                    if (monthSelectedIsAfterDateRange()) {
                      updatedDate.setMonth(currentMonth - 1);
                      setSelectedMonth(addMonth(currentMonth));
                    }
                    if (updatedYear !== updatedDate.getFullYear()) {
                      updatedDate.setFullYear(updatedYear);
                    }
                    setDateForDisplay(updatedDate);
                  }}
                >
                  <option value={currentYear}>{currentYear}</option>
                  {Array.from({ length: yearRange }).map((_, index) => {
                    return (
                      <option key={index} value={currentYear + index + 1}>
                        {currentYear + index + 1}
                      </option>
                    );
                  })}
                </select>
                <button
                  className={`${nextIsDisabled ? 'disable-navigation' : ''
                    } btn btn-link p-0`}
                  id="next-month"
                  onClick={() => {
                    if (nextIsDisabled) {
                      return;
                    }
                    const updatedDate = new Date(dateForDisplay);
                    const nextMonth = dateForDisplay.getMonth() + 1;
                    updatedDate.setMonth(nextMonth);
                    setDateForDisplay(updatedDate);
                    setSelectedMonth(nextMonth + 1);
                    if (nextMonth === 12) {
                      setSelectedYear(selectedYear + 1);
                    }
                  }}
                >
                  <img
                    src="/next.svg"
                    alt="Next"
                    id={`datepicker-next-button`}
                    width="30"
                    height="40"
                    className="no-resize"
                  />
                </button>
              </div>
              <ReactCalendar
                numMonths={'2'}
                dateForDisplay={dateForDisplay}
                date={today}
                onHeaderRender={headerText => {
                  <div>{headerText}</div>;
                }}
                onDayRender={({
                  dayInMonth,
                  classList,
                  dateHoverRange,
                  resetRangeOnClick,
                  ...props
                }) => {
                  // const currentDate = formatDateWithDashes(dayInMonth);

                  const dateString = dayInMonth.toString();
                  if (dayInMonth < bookingAllowedStartDate) {
                    // console.log('dateString!', dateString)
                    classList.push(`before-booking-allowed`);
                  }

                  const diffDays = Math.floor(
                    (Date.UTC(
                      dayInMonth.getFullYear(),
                      dayInMonth.getMonth(),
                      dayInMonth.getDate()
                    ) -
                      Date.UTC(
                        calendarStartDate.getFullYear(),
                        calendarStartDate.getMonth(),
                        calendarStartDate.getDate()
                      )) /
                    (1000 * 60 * 60 * 24)
                  );

                  if (minStayAlertDays[diffDays] === 'Y') {
                    classList.push(`min-stay-dots`);
                    classList.push(`reserved-full-day`);
                  } else if (
                    availableDays[diffDays] === 'N' &&
                    availableDays[diffDays - 1] === 'Y' &&
                    minStayAlertDays[diffDays - 1] !== 'Y'
                  ) {
                    classList.push(`reserved-check-in`);
                  } else if (
                    availableDays[diffDays] === 'Y' &&
                    availableDays[diffDays - 1] === 'N'
                  ) {
                    classList.push(`reserved-check-out`);
                  } else {
                    classList.push(
                      `${availableDays[diffDays] === 'Y'
                        ? ''
                        : 'reserved-full-day'
                      }`
                    );
                  }

                  return (
                    <DayCell
                      {...props}
                      dayInMonth={dayInMonth}
                      key={dayInMonth}
                      classList={classList}
                      onClick={() => {
                        if (allowBooking) {

                          setBookingModalIsOpen(true);
                        }
                      }}
                    />
                  );
                }}
              />
              <Modal
                // expanded={true}
                className="modalSmallFixed"
                isOpen={bookingModalIsOpen}
                title={'Reserve your Dates'}
                onClose={() => {
                  setBookingModalIsOpen(false);
                }}
                disableFocusTrap={true}
              >
                <PropertyBookingCalendar
                  propertyKey={data?.property?.key}
                  promo={promo}
                  onClose={() => {
                    setBookingModalIsOpen(false);
                  }}
                />
              </Modal>
            </div>
            {/* )} */}
          </>
        );
      }}
    </Query>
  );
};
export default SinglePropertyAvailabilityCalendar;
