import React, { useEffect, useState, createRef } from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './styles.scss';
const PROPERTY_REVIEWS = gql`
  query GuestReservationReviewConnection($propertyId: Int) {
    guestReservationReviewConnection(
      where: { propertyId: $propertyId, siteStatus: FEATURED }
    ) {
      aggregate {
        avg {
          fiveStarRating
        }
      }
      edges {
        node {
          id
          headline
          body
          firstName
          lastName
          reviewDate
          fiveStarRating
          property {
            id
          }
        }
      }
    }
  }
`;
// import './styles.scss';
// import StarImg from '../../../../../images/star.svg';

const StarIcons = ({ numberOfStars }) => {
  return Array.from({ length: Number(numberOfStars) }).map((_, index) => {
    return <img width="20" key={index} src="/rating-on.svg" />; //<img key={index} width="20" src={StarImg} />;
  });
};
const PropertyReviews = ({ propertyId }) => {
  //console.log('props', props);
  const { data, error, loading } = useQuery(PROPERTY_REVIEWS, {
    variables: { propertyId },
  });
  const [viewMoreClicked, setViewMoreClicked] = useState(false);
  // const [testimonials, setViewMoreClicked] = useState(false);
  let scrollBarRef = createRef();
  useEffect(() => {
    if (loading == false && scrollBarRef.current) {
      scrollBarRef.current.updateScroll(); // Used to invoke scrollbar so it is visible by default (via css.)
    }
    // console.log('loading', loading); //.updateScroll()
    // console.log('scrollBarRef', scrollBarRef); //.updateScroll()
    // console.log('scrollBarRef', scrollBarRef.current); //.updateScroll()
  }, [loading]);
  // if (loading)
  //   return (
  //     <div
  //       className="pt-2"
  //       style={{ height: '40px', textAlign: 'center', width: '100%' }}
  //     >
  //       LOADING....
  //     </div>
  //   );
  if (error) return null;
  if (
    !loading &&
    data &&
    data.guestReservationReviewConnection.aggregate.avg.fiveStarRating == null
  )
    return null;
  return (
    <>
      <div className=" mt-2">
        <div className="h2" id="guesttestimonials">
          <span className="mr-2" style={{ verticalAlign: 'text-bottom' }}>
            Property Testimonials
          </span>
          {!loading && (
            <>
              <br class="d-md-none" />
              <b
                style={{
                  fontSize: '15px',
                  fontWeight: 600,
                }}
              >
                {data.guestReservationReviewConnection.aggregate.avg.fiveStarRating.toFixed(
                  1
                )}{' '}
                Rating - {data.guestReservationReviewConnection.edges.length}{' '}
                Testimonials
              </b>
            </>
          )}
        </div>
        {loading ? (
          <div
            className="pt-2"
            style={{ height: '40px', textAlign: 'center', width: '100%' }}
          >
            LOADING....
          </div>
        ) : (
          <div className="pr-2 mb-2" style={{ height: '500px' }}>
            <PerfectScrollbar ref={scrollBarRef}>
              {data.guestReservationReviewConnection.edges
                .slice(
                  0,
                  viewMoreClicked
                    ? data.guestReservationReviewConnection.edges.length
                    : 5
                )
                .map(({ node }) => {
                  //   console.log('node', node);
                  const {
                    firstName,
                    lastName,
                    reviewDate,
                    fiveStarRating,
                    headline,
                    body,
                  } = node;
                  return (
                    <div className="mb-2 pr-2">
                      <div className="mb-1">
                        <b style={{ fontWeight: 600 }}>
                          {firstName} {`${lastName}`.substr(0, 1)}
                        </b>
                      </div>
                      <div className="d-flex justify-content-between mb-1plus">
                        <div>
                          <StarIcons numberOfStars={fiveStarRating} />{' '}
                          <span
                            className="mr-1"
                            style={{ verticalAlign: 'middle' }}
                          >
                            {fiveStarRating}.0
                          </span>
                        </div>
                        <div>
                          {' '}
                          {new Date(reviewDate)
                            .toLocaleDateString('en', {
                              year: 'numeric',
                              month: 'numeric',
                              day: '2-digit',
                            })
                            .replace(/\//g, '-')}
                        </div>
                      </div>
                      <div>
                        <b style={{ fontWeight: 600 }}>{headline}</b>
                        <div>{body}</div>
                      </div>
                      <hr className="mt-2 mb-1" />
                    </div>
                  );
                })}
              {!viewMoreClicked && (
                <div className="text-center">
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      setViewMoreClicked(true);
                      scrollBarRef.current.updateScroll();
                    }}
                  >
                    View More
                  </button>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </div>
    </>
  );
};

export default PropertyReviews;
