import React, { useEffect, useState } from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Dots } from '../Loading';
import PropertyBookingCalendar from '../PropertyBookingCalendar';
import Modal from '../Modal';
import styles from './styles.module.scss';

// import './styles.scss';
const PROPERTY_PRICE_QUERY = gql`
  query PropertyPrice($propertyKey: String) {
    property(where: { key: $propertyKey }) {
      priceOffSeason
      ... on Property {
        priceLastMinuteDeal
      }
    }
  }
`;

function PropertyAvailability({
  propertyKey,
  multiplePerPage,
  client,
  allowBooking,
  promo,
  context,
  ...rest
}) {
  //console.log('rest', rest);
  const { data, error, loading } = useQuery(PROPERTY_PRICE_QUERY, {
    client,
    variables: { propertyKey },
  });
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
  useEffect(() => {
    $('[data-toggle="popover"]').popover({
      container: 'body',
    });
  }, [loading]);
  if (loading)
    return (
      <div
        className="pt-2"
        style={{ height: '40px', textAlign: 'center', width: '100%' }}
      >
        <Dots />
      </div>
    );
  if (error) return null;
  return (
    <>
      {context != 'button' && <p className={`${styles.availablityText} flex-grow-1 mb-0 text-right pr-3 text-nowrap`}>
        {data.property.priceLastMinuteDeal ? (
          <div className="pt-1" style={{ lineHeight: 1 }}>
            From{' '}
            <span className="property-price text-red font-weight-bold">
              ${data.property.priceLastMinuteDeal}
            </span>{' '}
            /night
            <a
              style={{ cursor: 'pointer' }}
              tabIndex="0"
              id="more-info"
              className="ml-1plus popover-dismiss"
              data-container="body"
              data-toggle="popover"
              data-placement="top"
              data-html="true"
              data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
              data-content={`This is a limited-time last-minute travel deal! Get a discounted rate on your next beach house vacation when you book at the last minute. These rates are only available for a short time and are based on availability, so don't wait to make your reservation. Select "How to Book" to take advantage of this opportunity to save on your next trip. Please note that last-minute rates may be non-refundable and have other restrictions.`}
            >
              <img src="/last-minute-deals.svg" width="22" height="22" />
            </a>
            <div className="text-red small">Last-Minute Deal</div>
          </div>
        ) : (
          <>
            From{' '}
            <span className="property-price font-weight-bold">
              ${data.property.priceOffSeason}
            </span>{' '}
            /night
            <a
              style={{ cursor: 'pointer' }}
              tabIndex="0"
              id="more-info"
              className="ml-1plus popover-dismiss"
              data-container="body"
              data-toggle="popover"
              data-placement="top"
              data-html="true"
              data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
              data-content={`Rates vary by night, depending on season, select holidays, and other considerations.  
          <br /><br />
          We use the same dynamic-pricing software as others in the hospitality and travel industry (such as airlines and hotels). Rates are automatically re-calibrated nightly, based on supply and demand.
          <br /><br />
          Weekend nights are priced higher than mid-week nights. Seasonality: Rates are higher during peak-demand seasons such as Spring Break, Summer, and select holidays. Rates are lowest during "value season": Late August to mid-November.  
          <br /><br />
          ${allowBooking ? '' : 'To see rates for your travel dates, please visit your favorite travel booking platform and search for "Stuart FL" or "Florida Treasure Coast" for more info.'}`}
            >
              <img src="/more-info.svg" width="22" height="22" />
            </a>
          </>
        )}
      </p>}
      {allowBooking ? (
        <>
          <button
            className={`${styles.checkAvailabilityButton
              } btn btn-secondary rounded text-primary font-weight-bold text-nowrap ${context == 'button' && 'h2'}`}
            onClick={() => {
              setBookingModalIsOpen(true);
            }}
          >
            {context == 'button' ? 'Check Availability' : 'Reserve your Dates'}
          </button>
          <Modal
            // expanded={true}
            className="modalSmallFixed"
            isOpen={bookingModalIsOpen}
            title={'Reserve your Dates'}
            onClose={() => {
              setBookingModalIsOpen(false);
            }}
            disableFocusTrap={true}
          >
            <PropertyBookingCalendar
              propertyKey={propertyKey}
              promo={promo}
              onClose={() => {
                setBookingModalIsOpen(false);
              }}
            />
          </Modal>
        </>
      ) : (
        <a
          className={`${styles.checkAvailabilityButton
            } btn btn-secondary rounded text-primary font-weight-bold`}
          href={`#availability${multiplePerPage ? propertyKey : ''}`}
        >
          <span className={styles.checkAvailablitySubtext}>How to</span> Book
        </a>
      )}
    </>
  );
}

export default withApollo(PropertyAvailability);
